import { OrgUser } from 'types/org_user'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface OrgUserStore {
  user: OrgUser
  setUser: (u: OrgUser) => void
  orgId: () => string
}

const useOrgUserStore = createWithEqualityFn<OrgUserStore>()(
  persist(
    (set, get) => ({
      user: { phone: '' },
      setUser: (u) => set({ user: u }),
      orgId: () => get().user.orgId || ''
    }),

    {
      name: 'zustand:mobile:org_user'
    }
  ),
  shallow
)

export default useOrgUserStore
