import { Project } from 'types/project'
import { persist, subscribeWithSelector } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import useTaskStore from './task'

interface ProjectStore {
  // 项目ID
  project: Project
  setProject: (p: Project) => void
  clear: () => void
}

const useProjectStore = createWithEqualityFn<ProjectStore>()(
  persist(
    subscribeWithSelector((set, get) => ({
      // 项目ID
      project: {},

      setProject: (p) => set({ project: p }),
      clear() {
        set({ project: {} })
      }
    })),

    {
      name: 'y:m:project',
      version: 2
    }
  ),
  shallow
)

export default useProjectStore
