import axios from 'axios'
import { clearToken, getToken, storeToken } from './storage'
import { Toast } from 'antd-mobile'

const http = axios.create({
  baseURL: '',
  timeout: 5000,
})

http.interceptors.request.use(
  (config) => {
    let token = getToken()
    if (token) {
      // 携带token传输
      config.headers!['token'] = token
    }
    return config
  },
  (error) => {
    console.log('req err', error)
    return Promise.reject(error.response)
  }
)
http.interceptors.response.use(
  (res) => {
    // console.log('response', res)
    // req.headers!['token'] = 'xxxx'

    if (res.status == 200) {
      if (res.headers.token && res.headers.token !== '') {
        storeToken(res.headers.token)
      }

      if (res.data.code == 1003) {
        clearToken()
        navigateTo('/login')
        return Promise.reject()
      }
    }
    return Promise.resolve(res)
  },
  function (err) {
    if (err.code == 'ERR_NETWORK') {
      Toast.show({
        content: '网络错误',
      })
    }

    return Promise.reject(err)
  }
)

const navigateTo = (url: string) => {
  location.href = url
}
//
interface GetReq {
  url: string
  params?: any
}
export const get = (opt: GetReq) => {
  return http({ method: 'get', ...opt }).then((res) => {
    return res.data.data
  })
}

// 用户可以自定义信息
export const post = (url: string, data?: any, msg?: string) =>
  http.post(url, data).then((res) => handleRes(res, msg || '添加成功'))

export const del = (url: string, data?: any) => http.delete(url, { data }).then((res) => handleRes(res, '删除成功'))

export const put = (url: string, data?: any) =>
  http
    .put(url, data)
    .then((res) => handleRes(res, '修改成功'))
    .catch((err) => {
      console.log(err)
    })

export default http

const handleRes = (res: any, msg: string) => {
  if (res.data.code == 200) {
    Toast.show(msg)
  } else {
    console.log('failed', res)
    Toast.show(res.data.msg)
    throw new Error('操作失败')
  }
  return res.data
}
