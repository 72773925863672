import { ReactComponent as DeleteOutlined } from 'assets/icons/DeleteOutlined.svg'
import { ReactComponent as Fill } from 'assets/icons/Fill.svg'
import { ReactComponent as Frame } from 'assets/icons/Frame.svg'
import { ReactComponent as Rectangle } from 'assets/icons/Rectangle.svg'
import { ReactComponent as SelectorCheck } from 'assets/icons/SelectorCheck.svg'
import { ReactComponent as ShoppingCart } from 'assets/icons/ShoppingCart.svg'
import { ReactComponent as UploadTopVector } from 'assets/icons/TopVector.svg'
import { ReactComponent as UpLoadVector } from 'assets/icons/Vector.svg'
import { ReactComponent as AlertSvg } from 'assets/icons/alert.svg'
import { ReactComponent as BackSvg } from 'assets/icons/back.svg'
import { ReactComponent as BodySvg } from 'assets/icons/body.svg'
import { ReactComponent as BuildingSvg } from 'assets/icons/building.svg'
import { ReactComponent as CameraSvg } from 'assets/icons/camera.svg'
import { ReactComponent as CheckBorderSvg } from 'assets/icons/check_border.svg'
import { ReactComponent as CloseSvg } from 'assets/icons/close.svg'
import { ReactComponent as CompanySvg } from 'assets/icons/company.svg'
import { ReactComponent as DeleteSvg } from 'assets/icons/delete.svg'
import { ReactComponent as DoneSvg } from 'assets/icons/done.svg'
import { ReactComponent as NotDoneInSvg } from 'assets/icons/dot.svg'
import { ReactComponent as NotDoneOutSvg } from 'assets/icons/dotout.svg'
import { ReactComponent as DownSvg } from 'assets/icons/down.svg'
import { ReactComponent as DownwardSvg } from 'assets/icons/downward.svg'
import { ReactComponent as Edge } from 'assets/icons/edge.svg'
import { ReactComponent as EditSvg } from 'assets/icons/edit.svg'
import { ReactComponent as Edit2Svg } from 'assets/icons/edit2.svg'
import { ReactComponent as Edit3Svg } from 'assets/icons/edit3.svg'
import { ReactComponent as EditingSvg } from 'assets/icons/editing.svg'
import { ReactComponent as EmptyImgSvg } from 'assets/icons/empty-img.svg'
import { ReactComponent as ExitSvg } from 'assets/icons/exit.svg'
import { ReactComponent as EyeSvg } from 'assets/icons/eye.svg'
import { ReactComponent as HammerLineSvg } from 'assets/icons/hammer_line.svg'
import { ReactComponent as HouseSvg } from 'assets/icons/house.svg'
import { ReactComponent as HouseFill } from 'assets/icons/house_fill.svg'
import { ReactComponent as IdcardSvg } from 'assets/icons/id_card.svg'
import { ReactComponent as LargeLogo } from 'assets/icons/largeLogo.svg'
import { ReactComponent as MoneySvg } from 'assets/icons/money.svg'
import { ReactComponent as MoveSvg } from 'assets/icons/move.svg'
import { ReactComponent as NotBegin } from 'assets/icons/notBegin.svg'
import { ReactComponent as NotOrderSvg } from 'assets/icons/not_order.svg'
import { ReactComponent as NotStartSvg1 } from 'assets/icons/not_started.svg'
import { ReactComponent as NotStartSvg2 } from 'assets/icons/not_started2.svg'
import { ReactComponent as NoteSvg } from 'assets/icons/note.svg'
import { ReactComponent as OngoingSvg } from 'assets/icons/ongoing.svg'
import { ReactComponent as OptionSvg } from 'assets/icons/option.svg'
import { ReactComponent as Doingout } from 'assets/icons/orangeout.svg'
import { ReactComponent as OrderedSvg } from 'assets/icons/ordered.svg'
import { ReactComponent as OrgSvg } from 'assets/icons/org.svg'
import { ReactComponent as PaperSvg } from 'assets/icons/paper.svg'
import { ReactComponent as PickerSvg } from 'assets/icons/picker.svg'
import { ReactComponent as PlusSvg } from 'assets/icons/plus.svg'
import { ReactComponent as PreviewSvg } from 'assets/icons/preview.svg'
import { ReactComponent as PrintSvg } from 'assets/icons/print.svg'
import { ReactComponent as ProductSvg } from 'assets/icons/product.svg'
import { ReactComponent as Product2Svg } from 'assets/icons/product2.svg'
import { ReactComponent as ProductFill } from 'assets/icons/product_fill.svg'
import { ReactComponent as QuestionSvg } from 'assets/icons/question.svg'
import { ReactComponent as QuestionFill } from 'assets/icons/question_fill.svg'
import { ReactComponent as RepreviewSvg } from 'assets/icons/re-shot.svg'
import { ReactComponent as RefreshSvg } from 'assets/icons/refresh.svg'
import { ReactComponent as RingSvg } from 'assets/icons/ring.svg'
import { ReactComponent as RotateSvg } from 'assets/icons/rotate.svg'
import { ReactComponent as RulerSvg } from 'assets/icons/ruler.svg'
import { ReactComponent as SaveSvg } from 'assets/icons/save.svg'
import { ReactComponent as ScaleSvg } from 'assets/icons/scale.svg'
import { ReactComponent as Shopping } from 'assets/icons/shopping.svg'
import { ReactComponent as ShoppingFinsh } from 'assets/icons/shoppingFinsh.svg'
import { ReactComponent as ShotSvg } from 'assets/icons/shot.svg'
import { ReactComponent as SpinSvg } from 'assets/icons/spin.svg'
import { ReactComponent as StepCheckSvg } from 'assets/icons/step1.svg'
import { ReactComponent as StepWorkSvg } from 'assets/icons/step2.svg'
import { ReactComponent as StepShopSvg } from 'assets/icons/step3.svg'
import { ReactComponent as StepDesignSvg } from 'assets/icons/step4.svg'
import { ReactComponent as StepGuessSvg } from 'assets/icons/step5.svg'
import { ReactComponent as TextSvg } from 'assets/icons/text.svg'
import { ReactComponent as TitleSvg } from 'assets/icons/title.svg'
import { ReactComponent as UpwardSvg } from 'assets/icons/upward.svg'
import { ReactComponent as User2Svg } from 'assets/icons/user2.svg'
import { ReactComponent as UsersSvg } from 'assets/icons/users.svg'
import { ReactComponent as UsersFill } from 'assets/icons/users_fill.svg'
import { ReactComponent as VideoSvg } from 'assets/icons/video.svg'
import { ReactComponent as Yuqi } from 'assets/icons/yuqi.svg'
import { ReactComponent as Yuqilogo } from 'assets/icons/yuqilogo.svg'
const AddrSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M10 17.4176L14.1248 13.2927C16.4028 11.0147 16.4028 7.32124 14.1248 5.04318C11.8468 2.76512 8.15327 2.76512 5.87521 5.04318C3.59715 7.32124 3.59715 11.0147 5.87521 13.2927L10 17.4176ZM10 19.7746L4.6967 14.4713C1.76777 11.5423 1.76777 6.7936 4.6967 3.86467C7.62563 0.935735 12.3743 0.935735 15.3033 3.86467C18.2323 6.7936 18.2323 11.5423 15.3033 14.4713L10 19.7746ZM10 10.8346C10.9205 10.8346 11.6667 10.0885 11.6667 9.16797C11.6667 8.24749 10.9205 7.5013 10 7.5013C9.0795 7.5013 8.33333 8.24749 8.33333 9.16797C8.33333 10.0885 9.0795 10.8346 10 10.8346ZM10 12.5013C8.15905 12.5013 6.66667 11.0089 6.66667 9.16797C6.66667 7.32702 8.15905 5.83464 10 5.83464C11.8409 5.83464 13.3333 7.32702 13.3333 9.16797C13.3333 11.0089 11.8409 12.5013 10 12.5013Z"
        fill="currentColor"
      />
    </svg>
  )
}

const AddrFilledSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M12.2427 11.5767L8 15.8193L3.75736 11.5767C1.41421 9.23345 1.41421 5.43449 3.75736 3.09134C6.10051 0.748198 9.89947 0.748198 12.2427 3.09134C14.5858 5.43449 14.5858 9.23345 12.2427 11.5767ZM8 10.0007C9.47273 10.0007 10.6667 8.80672 10.6667 7.33398C10.6667 5.86122 9.47273 4.66732 8 4.66732C6.52724 4.66732 5.33333 5.86122 5.33333 7.33398C5.33333 8.80672 6.52724 10.0007 8 10.0007ZM8 8.66732C7.2636 8.66732 6.66667 8.07038 6.66667 7.33398C6.66667 6.5976 7.2636 6.00065 8 6.00065C8.7364 6.00065 9.33333 6.5976 9.33333 7.33398C9.33333 8.07038 8.7364 8.66732 8 8.66732Z"
        fill="currentColor"
        // fill-opacity="0.3"
      />
    </svg>
  )
}

const ClockSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99935 18.3327C5.39697 18.3327 1.66602 14.6017 1.66602 9.99935C1.66602 5.39697 5.39697 1.66602 9.99935 1.66602C14.6017 1.66602 18.3327 5.39697 18.3327 9.99935C18.3327 14.6017 14.6017 18.3327 9.99935 18.3327ZM10.8327 9.99935V5.83268H9.16602V11.666H14.166V9.99935H10.8327Z"
        fill="currentColor"
      />
    </svg>
  )
}

const AssessSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5 3V6.5H15V3H16.6722C17.1293 3 17.5 3.38933 17.5 3.86923V17.8808C17.5 18.3608 17.1292 18.75 16.6722 18.75H3.32783C2.87063 18.75 2.5 18.3607 2.5 17.8808V3.86923C2.5 3.38917 2.87079 3 3.32783 3H5ZM6.66667 1.25H13.3333V4.75H6.66667V1.25Z"
        fill="currentColor"
      />
    </svg>
  )
}

const DesignSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.17379 11.231L6.73999 12.7971L7.95138 11.5857L6.38519 10.0196L7.59657 8.80822L9.1628 10.3744L10.3742 9.16305L8.80796 7.59686L10.0194 6.38551L11.5856 7.95166L12.7969 6.7403L11.2308 5.17414L13.6535 2.75142C13.988 2.41691 14.5304 2.41691 14.8649 2.75142L18.4991 6.38551C18.8336 6.72001 18.8336 7.26235 18.4991 7.59686L7.59657 18.4991C7.26205 18.8336 6.7197 18.8336 6.38519 18.4991L2.751 14.865C2.41649 14.5305 2.41649 13.9881 2.751 13.6537L5.17379 11.231ZM12.4421 16.0759L16.0763 12.4419L18.334 14.6995V18.3342H14.7004L12.4421 16.0759ZM5.17367 8.80768L2.75088 6.38496C2.41637 6.05045 2.41637 5.50811 2.75088 5.1736L5.17367 2.75088C5.50818 2.41637 6.05055 2.41637 6.38506 2.75088L8.80784 5.1736L5.17367 8.80768Z"
        fill="currentColor"
      />
    </svg>
  )
}

const CartSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.15927 5.36041L1.25 2.49827L2.51882 1.25L5.42808 4.11215H19.1028C19.5983 4.11215 20 4.50732 20 4.9948C20 5.08071 19.9873 5.16615 19.9622 5.24843L17.809 12.3097C17.6951 12.683 17.3458 12.9387 16.9496 12.9387H5.95365V14.704H15.8228V16.4694H5.05646C4.56095 16.4694 4.15927 16.0742 4.15927 15.5867V5.36041ZM5.50505 20C4.76179 20 4.15927 19.4072 4.15927 18.676C4.15927 17.9448 4.76179 17.352 5.50505 17.352C6.24831 17.352 6.85084 17.9448 6.85084 18.676C6.85084 19.4072 6.24831 20 5.50505 20ZM16.2714 20C15.528 20 14.9256 19.4072 14.9256 18.676C14.9256 17.9448 15.528 17.352 16.2714 17.352C17.0146 17.352 17.6172 17.9448 17.6172 18.676C17.6172 19.4072 17.0146 20 16.2714 20Z"
        fill="currentColor"
      />
    </svg>
  )
}

const HammerSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.2365 6.60714V1.25H17.8716C18.3568 1.25 18.75 1.64975 18.75 2.14286V5.71429C18.75 6.20739 18.3568 6.60714 17.8716 6.60714H15.2365ZM13.4797 19.1071C13.4797 19.6003 13.0865 20 12.6014 20H9.08784C8.60273 20 8.20946 19.6003 8.20946 19.1071V6.60714H2.5V4.88783C2.5 4.57027 2.66593 4.27661 2.93579 4.1166L7.77027 1.25H13.4797V19.1071Z"
        fill="currentColor"
      />
    </svg>
  )
}

const CheckSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.875 2.125V17.8822C16.875 18.3615 16.5351 18.75 16.1162 18.75H3.88385C3.46475 18.75 3.125 18.3516 3.125 17.8822V2.11783C3.125 1.63854 3.46489 1.25 3.88385 1.25H16.1111C16.533 1.25 16.875 1.64175 16.875 2.125ZM9.45985 10.9811L7.56932 8.8156L6.48902 10.0531L9.45985 13.456L13.781 8.50629L12.7007 7.26885L9.45985 10.9811Z"
        fill="currentColor"
      />
    </svg>
  )
}

const MessageNullSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.29117 20.8242L2 22L3.17581 16.7088C2.42544 15.3056 2 13.7025 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C10.2975 22 8.6944 21.5746 7.29117 20.8242ZM7.58075 18.711L8.23428 19.0605C9.38248 19.6745 10.6655 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 13.3345 4.32549 14.6175 4.93949 15.7657L5.28896 16.4192L4.63416 19.3658L7.58075 18.711Z"
        fill="currentColor"
      />
    </svg>
  )
}

const MessageSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M7.29117 20.8242L2 22L3.17581 16.7088C2.42544 15.3056 2 13.7025 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C10.2975 22 8.6944 21.5746 7.29117 20.8242ZM7.58075 18.711L8.23428 19.0605C9.38248 19.6745 10.6655 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 13.3345 4.32549 14.6175 4.93949 15.7657L5.28896 16.4192L4.63416 19.3658L7.58075 18.711Z"
        fill="currentColor"
      />
      <circle cx="7.55939" cy="12.0008" r="1.21955" fill="currentColor" />
      <circle cx="12.0008" cy="12.0008" r="1.21955" fill="currentColor" />
      <circle cx="16.4383" cy="12.0008" r="1.21955" fill="currentColor" />
    </svg>
  )
}

function MessageFilledSvg({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M4.86143 13.8835L1.33398 14.6673L2.11786 11.1399C1.61761 10.2044 1.33398 9.13565 1.33398 8.00065C1.33398 4.31875 4.31875 1.33398 8.00065 1.33398C11.6825 1.33398 14.6673 4.31875 14.6673 8.00065C14.6673 11.6825 11.6825 14.6673 8.00065 14.6673C6.86565 14.6673 5.79692 14.3837 4.86143 13.8835Z"
        fill="currentColor"
      />
    </svg>
  )
}

const RightArrowSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.1716 11.0009L10.8076 5.63687L12.2218 4.22266L20 12.0009L12.2218 19.779L10.8076 18.3648L16.1716 13.0009H4V11.0009H16.1716Z"
        fill="currentColor"
      />
    </svg>
  )
}

function PhoneSvg({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M14 10.9467V13.3041C14 13.6541 13.7294 13.9445 13.3803 13.9691C13.0887 13.9897 12.8509 14 12.6667 14C6.7756 14 2 9.2244 2 3.33333C2 3.14914 2.0103 2.91125 2.0309 2.61967C2.05558 2.27059 2.34596 2 2.69591 2H5.0534C5.22452 2 5.36784 2.12961 5.38502 2.29987C5.40045 2.45271 5.41479 2.57543 5.42805 2.66801C5.5629 3.60981 5.83835 4.50624 6.23247 5.33535C6.29573 5.46843 6.25447 5.62773 6.13457 5.71337L4.6957 6.7412C5.57168 8.7874 7.2126 10.4283 9.2588 11.3043L10.2847 9.86793C10.3715 9.7466 10.5327 9.70487 10.6673 9.7688C11.4964 10.1626 12.3927 10.4377 13.3344 10.5723C13.4264 10.5855 13.5483 10.5997 13.7001 10.615C13.8704 10.6322 14 10.7755 14 10.9467Z"
        fill="currentColor"
      />
    </svg>
  )
}

function MobilePhoneSvg({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.83268 3.33464V16.668H14.166V3.33464H5.83268ZM4.99935 1.66797H14.9993C15.4596 1.66797 15.8327 2.04107 15.8327 2.5013V17.5013C15.8327 17.9616 15.4596 18.3346 14.9993 18.3346H4.99935C4.53912 18.3346 4.16602 17.9616 4.16602 17.5013V2.5013C4.16602 2.04107 4.53912 1.66797 4.99935 1.66797ZM9.99935 14.168C10.4596 14.168 10.8327 14.5411 10.8327 15.0013C10.8327 15.4616 10.4596 15.8346 9.99935 15.8346C9.5391 15.8346 9.16602 15.4616 9.16602 15.0013C9.16602 14.5411 9.5391 14.168 9.99935 14.168Z"
        fill="currentColor"
      />
    </svg>
  )
}

function DateExtra({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.00065 0.666504V1.99984H10.0007V0.666504H11.334V1.99984H14.0007C14.3689 1.99984 14.6673 2.29832 14.6673 2.6665V13.3332C14.6673 13.7014 14.3689 13.9998 14.0007 13.9998H2.00065C1.63246 13.9998 1.33398 13.7014 1.33398 13.3332V2.6665C1.33398 2.29832 1.63246 1.99984 2.00065 1.99984H4.66732V0.666504H6.00065ZM13.334 7.33317H2.66732V12.6665H13.334V7.33317ZM5.33398 8.6665V9.99984H4.00065V8.6665H5.33398ZM8.66732 8.6665V9.99984H7.33398V8.6665H8.66732ZM12.0007 8.6665V9.99984H10.6673V8.6665H12.0007ZM4.66732 3.33317H2.66732V5.99984H13.334V3.33317H11.334V4.6665H10.0007V3.33317H6.00065V4.6665H4.66732V3.33317Z"
        fill="currentColor"
      />
    </svg>
  )
}

function FoldersSvg({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M4.99935 5.83333V3.33333C4.99935 2.8731 5.37245 2.5 5.83268 2.5H11.1778L12.8445 4.16667H17.4993C17.9596 4.16667 18.3327 4.53977 18.3327 5V13.3333C18.3327 13.7936 17.9596 14.1667 17.4993 14.1667H14.9993V16.6667C14.9993 17.1269 14.6263 17.5 14.166 17.5H2.49935C2.03912 17.5 1.66602 17.1269 1.66602 16.6667V6.66667C1.66602 6.20643 2.03912 5.83333 2.49935 5.83333H4.99935ZM4.99935 7.5H3.33268V15.8333H13.3327V14.1667H4.99935V7.5ZM6.66602 4.16667V12.5H16.666V5.83333H12.1542L10.4875 4.16667H6.66602Z"
        fill="currentColor"
      />
    </svg>
  )
}

export {
  AddrSvg,
  AddrFilledSvg,
  AlertSvg,
  AssessSvg,
  BackSvg,
  BodySvg,
  BuildingSvg,
  CameraSvg,
  CartSvg,
  CheckBorderSvg,
  CheckSvg,
  ClockSvg,
  CloseSvg,
  CompanySvg,
  DateExtra,
  DeleteOutlined,
  DeleteSvg,
  DesignSvg,
  Doingout,
  DoneSvg,
  DownSvg,
  DownwardSvg,
  Edge,
  Edit2Svg,
  Edit3Svg,
  EditSvg,
  EditingSvg,
  EmptyImgSvg,
  ExitSvg,
  EyeSvg,
  Fill,
  FoldersSvg,
  Frame,
  HammerLineSvg,
  HammerSvg,
  HouseFill,
  HouseSvg,
  IdcardSvg,
  LargeLogo,
  MessageFilledSvg,
  MessageNullSvg,
  MessageSvg,
  MobilePhoneSvg,
  MoneySvg,
  MoveSvg,
  NotBegin,
  NotDoneInSvg,
  NotDoneOutSvg,
  NotOrderSvg,
  NotStartSvg1,
  NotStartSvg2,
  NoteSvg,
  OngoingSvg,
  OptionSvg,
  OrderedSvg,
  OrgSvg,
  PaperSvg,
  PhoneSvg,
  PickerSvg,
  PlusSvg,
  PreviewSvg,
  PrintSvg,
  Product2Svg,
  ProductFill,
  ProductSvg,
  QuestionFill,
  QuestionSvg,
  Rectangle,
  RefreshSvg,
  RepreviewSvg,
  RightArrowSvg,
  RingSvg,
  RotateSvg,
  RulerSvg,
  SaveSvg,
  ScaleSvg,
  SelectorCheck,
  Shopping,
  ShoppingCart,
  ShoppingFinsh,
  ShotSvg,
  SpinSvg,
  StepCheckSvg,
  StepDesignSvg,
  StepGuessSvg,
  StepShopSvg,
  StepWorkSvg,
  TextSvg,
  TitleSvg,
  UpLoadVector,
  UploadTopVector,
  UpwardSvg,
  User2Svg,
  UsersFill,
  UsersSvg,
  VideoSvg,
  Yuqi,
  Yuqilogo
}
