import { Button, Form, Input } from 'antd-mobile'
import dayjs, { Dayjs } from 'dayjs'
import { get, post } from 'lib/axios'
import { validatePhone } from 'lib/phone'
import { storeToken } from 'lib/storage'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import clearStore from 'store/clear'
import useOrgUserStore from 'store/org_user'
import { OrgUser } from 'types/org_user'

export default function LoginForm() {
  const navigate = useNavigate()
  const [sending, setSending] = useState(false)
  const [end, setEnd] = useState<Dayjs>(dayjs())

  const [phone, setPhone] = useState('')
  const [captcha, setCaptcha] = useState('')

  useEffect(() => {
    setCanSend(validatePhone(phone))
  }, [phone])

  const [canSend, setCanSend] = useState(false)

  const setUser = useOrgUserStore((state) => state.setUser)

  const login = () => {
    // post("/api/org_user/login", { phone, captcha }).then((res) => {
    //   console.log("login ", res, phone);

    //   switch (res.code) {
    //     case 200:
    //       // storeToken(res.data.token)
    //       let user = res.data as OrgUser;
    //       setUser(user);
    //       navigate("/");
    //   }
    // });

    // 使用密码登录
    let vs = loginForm.getFieldsValue()
    post('/api/org_user/login', vs, '登录成功').then((res) => {
      console.log('login ', res, phone)
      switch (res.code) {
        case 200:
          storeToken(res.data.token)
          let user = res.data.user as OrgUser
          setUser(user)
          navigate('/')

          // 清空状态
          clearStore()
      }
    })
  }

  const [loginForm] = Form.useForm()

  const getCaptcha = () => {
    if (!canSend) {
      loginForm.setFields([{ name: 'phone', errors: ['请输入正确的手机号'] }])
      return
    }

    setEnd(dayjs().add(60, 's'))
    setSending(true)

    console.log('phone', phone)

    get({ url: '/api/org_user/sms', params: { phone: phone } }).then((res) => {
      console.log('get res ', res)
    })
  }

  return (
    <Form layout="vertical" form={loginForm}>
      <Form.Item label="账号" name="username">
        <Input />
      </Form.Item>
      <Form.Item label="密码" name="password">
        <Input clearable />
      </Form.Item>

      {/* <Form.Item label="手机号" name="phone">
        <Input
          onChange={(v) => {
            setPhone(v)
          }}
          type="number"
          value={phone}
          placeholder="请输入"
        />
      </Form.Item>
      <Form.Item className="mr-2" label="验证码">
        <div className="flex justify-between">
          <Input
            className="flex-1 mr-4"
            onChange={(v) => setCaptcha(v)}
            value={captcha}
            placeholder="请输入"
            type="number"
          />
          <Button className="w-[120px]" disabled={sending} onClick={getCaptcha}>
            {sending ? (
              <div className="flex justify-center">
                <Countdown
                  end={end}
                  onEnd={() => {
                    setSending(false)
                  }}
                />
                秒
              </div>
            ) : (
              '获取验证码'
            )}
          </Button>
        </div>
      </Form.Item> */}

      <Form.Item>
        <Button className="w-full" onClick={login}>
          登录
        </Button>
      </Form.Item>
    </Form>
  )
}
