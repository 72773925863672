import { Button, Image, Modal, NoticeBar } from 'antd-mobile'

import OK from 'assets/icons/ok.png'
import { Edge, Fill, Yuqilogo } from 'components/icons'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginForm from './form'
import cover from '/cover.png'
export default function Login() {
  const usePromot = () => {
    const [installEvent, setEvent] = useState<any>(null)
    const handlePromot = (event) => {
      event.preventDefault() // 阻止浏览器默认的安装提示
      setEvent(event)
    }
    useEffect(() => {
      window.addEventListener('beforeinstallprompt', handlePromot)
      return () => {
        window.removeEventListener('beforeinstallprompt', handlePromot)
      }
    }, [])

    return {
      installEvent
    }
  }

  const whyDoit = () => {
    Modal.alert({
      title: <>为什么要这样做</>,
      content: (
        <div>
          <div className="w-full justify-center flex">
            <div className="flex flex-col items-center text-[#0F1820]">
              <Edge />
              <div>Edge浏览器</div>
            </div>
            <div className="flex flex-col items-center ml-4 text-[#0F1820]">
              <Yuqilogo className="" />
              <div>适老化改造</div>
            </div>
          </div>
          <div className="w-[290px] mt-2 leading-tight text-[#60676F]">
            “适老化改造”本身是一个网页应用，通过Edge浏览器可以将它作为一个app安装在桌面使用。
          </div>
          <div className="w-[290px] leading-snug  text-[#60676F]">
            由于版本迭代，它相较于一般app在更新时可以不用在应用商店下载，会减少很多麻烦。
          </div>
        </div>
      )
    })
  }
  const openModal = () => {
    Modal.confirm({
      title: '安装到桌面',
      content: (
        <div className="flex justify-between items-center w-full">
          <div className="ml-4 ">
            <div>桌面快解方式</div>
            <div> 添加桌面快捷方式</div>
          </div>
          <div
            style={{ backgroundImage: `url(${OK})` }}
            className="w-16 h-16 "
          ></div>
        </div>
      ),
      onConfirm() {
        handleInstallClick()
      }
    })
  }
  const navigate = useNavigate()
  const isChrome = navigator.userAgent.indexOf('Chrome') > -1
  const { installEvent } = usePromot()
  console.log(installEvent, 'installEvent')
  const handleInstallClick = async () => {
    await installEvent.prompt()
  }
  return (
    <div className="flex h-full relative animate__animated animate__fadeIn">
      {!isChrome && (
        <NoticeBar
          content={
            <div className="flex">
              为了不影响您的使用，请前去应用商店下载Edge浏览器后再进入此页面。
              <div onClick={whyDoit} className="text-[#418CEA] ml-2">
                为什么要这么做？
              </div>
            </div>
          }
          className=" absolute z-10 w-full"
          color="alert"
          closeable
        />
      )}
      <div className="relative w-1/2">
        <Image src={cover} alt={'cover'} className="h-full " />

        <div className="absolute bottom-20 text-white w-full flex flex-col items-center">
          <div className="text-white mb-0">适老化改造平台</div>
          <div className="text-white">专注为中老年人提供适老化改造服务</div>
        </div>
      </div>

      <div className="flex-1 h-full flex justify-center">
        <div className={`pt-20  w-[380px] relative`}>
          <div className="h-fit bg-white rounded-xl p-6">
            <div>欢迎登录</div>

            <div>
              <LoginForm />
            </div>
          </div>

          <div className="w-full absolute bottom-4 flex flex-col items-center justify-center space-y-4">
            {installEvent && (
              <Button
                onClick={openModal}
                color="primary"
                className="px-6 rounded-lg  "
              >
                <div className="flex items-center h-10">
                  <Yuqilogo className="w-5 h-5" />{' '}
                  <div className="ml-2 text-lg">安装到桌面</div>
                </div>
              </Button>
            )}
            <div className="flex mx-auto text-xs">
              <div className="flex items-center">
                <Fill />
                <div
                  onClick={() => {
                    navigate('/install')
                  }}
                  className="ml-1 text-blue-500"
                >
                  安装帮助
                </div>
              </div>
              {/* <Iconfont type="icon-question-fill" className="w-2 h-2 text-red-50" /> 23 */}
              {/* <div className="text-xs">宇齐适老化改造</div>
              <VerticalDivider />
              <Link to={'/privacy'} className="text-link">
                <div>隐私条款</div>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
