import { Button } from 'antd-mobile'
import { SpinSvg } from './icons'
import { Size } from 'lib/constants'

export type ButtonType = 'primary' | 'link' | 'text' | 'default'

const buttonStyles = {
  primary: ' border-transparent bg-blue-500 text-white font-medium',
  link: 'border-transparent bg-transparent text-blue-500',
  text: 'border-transparent bg-transparent text-text1',
  default: 'border-[rgba(0,0,0,0.18)]  bg-white text-text1',
}

const buttonSizes = {
  small: 'h-8',
  middle: 'h-10',
  large: 'h-12',
}

export default function MButton({
  loading,
  icon,
  info = '',
  disabled = false,
  onClick,
  className = '',
  type = 'default',
  size = 'middle',
  children,
}: {
  loading?: boolean
  icon?: React.ReactNode
  info?: React.ReactNode
  disabled?: boolean
  onClick?: () => void
  className?: string
  type?: ButtonType
  size?: Size
  children?: React.ReactNode
}) {
  return (
    <Button
      className={`
      ${buttonStyles[type]}
      flex items-center justify-center leading-none px-2 min-w-[64px]
      ${buttonSizes[size]}
      border
     
      ${className}
      `}
      loadingIcon={<SpinSvg className="animate-spin text-blue-500 w-6 h-6" />}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    >
      <div className="flex items-center justify-center text-inherit">
        <div className="">{info || children}</div>
        {icon && <div className="ml-1 ">{icon}</div>}
      </div>
    </Button>
  )
}
