import Iconfont from 'components/iconfont'
import MButton from 'components/m_button'
import { useState } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'

// 10分钟检查一次软件更新
const interval = 10 * 60 * 1000

function ReloadPrompt() {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (!r) return

      setInterval(() => {
        // eslint-disable-next-line no-console
        console.log('Checking for sw update', interval)
        r.update()
      }, interval /* 每小时检查一次更新 */)
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error)
    },
  })

  const close = () => {
    setNeedRefresh(false)
  }
  const [updateLoading, setUpdateLoading] = useState(false)

  return (
    <>
      {needRefresh && (
        <div className="fixed right-6 bottom-6 bg-white rounded-lg shadow-lg p-4">
          <div className="flex items-center">
            <Iconfont type="icon-error-warning-fill" className="text-[24px] text-orange-500 mr-2" />
            <div className="font-medium">检测到应用有更新，请立即更新</div>
          </div>
          <div className="flex justify-end mt-4 space-x-4">
            <MButton className="w-[96px]" onClick={() => close()}>
              取消
            </MButton>
            <MButton
              loading={updateLoading}
              className="w-[96px]"
              type="primary"
              onClick={async () => {
                setUpdateLoading(true)
                await updateServiceWorker(true)
                setUpdateLoading(false)
              }}
            >
              更新
            </MButton>
          </div>
        </div>
      )}
    </>
  )
}

export default ReloadPrompt
